<template>
  <div class="jd-logistics-list-box">
    <div
      v-for="(item, index) in data"
      :key="index"
      class="flex text-left c-999 jd-logistics-item-box"
      :class="{ 'c-333': index > 0 }"
    >
      <div class="flex-item text-left ml25 pr pl20 logistics-content pb30">
        <div class="line-height-16 fs12" :class="{ 'c-FE6507': index == 0 }">
          {{ item.content }}
        </div>
        <div class="line-height-12 fs12 mt8">
          {{ item.msgTime }}
        </div>
        <div class="step-circle-container">
          <div v-if="index == 0">
            <img class="h-25" :src="OSS_URL + '/shop/order-confirm.png'" />
          </div>
          <div v-else-if="index == data.length - 1">
            <img class="h-25" :src="OSS_URL + '/shop/submit-order.png'" />
          </div>
          <div v-else class="step-circle"></div>
        </div>
        <div class="step-line"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Array,
  },
  data() {
    return {
      OSS_URL: process.env.VUE_APP_OSS_URL,
    };
  },
};
</script>
<style lang="scss" scoped>
.jd-logistics-list-box {
  .jd-logistics-item-box {
    align-items: flex-start;
    .logistics-content {
      min-height: 36px;
      &:last-child {
        margin-bottom: 0;
      }
      .step-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        bottom: 0;
        background: rgba(0, 0, 0, 0.15);
      }
      .step-circle-container {
        position: absolute;
        top: 7px;
        left: 0.5px;
        font-size: 0;
        line-height: 0;
        transform: translate(-50%, -50%);
        z-index: 2;
        background: white;
        .step-circle {
          width: 5px;
          height: 5px;
          background: #d8d8d8;
          border-radius: 50%;
        }
      }
    }
    &:last-child {
      .logistics-content {
        .step-line {
          width: 0;
        }
      }
    }
  }
}
</style>

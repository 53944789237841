<template>
  <div class="orderTracking">
    <navbarTools :leftArrow="true" :fixed="true"></navbarTools>
    <div class="wapper bgc-F7F7F7 jd-order-tracking-wrap">
      <div class="pt10">
        <div class="bgc-fff pl15 pr15 pt20 pb20">
          <div class="flex text-left c-333 fs14 line-height-14">
            <div class="w-85">运单号：</div>
            <div class="flex-item text-left ml5">
              <span>{{ expressSn }}</span>
              <span class="fs0 line-height-0 ml10">
                <img
                  class="h-12"
                  :src="OSS_URL + '/shop/copy.png?'"
                  @click="copyFn(info?.expressSn)"
                />
              </span>
            </div>
          </div>
          <div class="flex text-left c-333 fs14 line-height-14 mt15">
            <div class="w-85">国内承运人：</div>
            <div class="flex-item text-left ml5">
              <span>{{ expressName }}</span>
            </div>
          </div>
        </div>
        <!-- 物流信息 -->
        <div class="mt10 bgc-FFF logistics-wrap pt20 pr15">
          <div>
            <logistics :data="info?.orderTrack"></logistics>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import logistics from "@/components/shop/logistics";
import navbarTools from "@/components/navbarTools";
import { reactive, defineComponent, toRefs } from "vue";
import { getJdDeliverInfo } from "@/api/shop";
import { useRoute } from "vue-router";
import useClipboard from "vue-clipboard3";
import { Toast } from "vant";
export default defineComponent({
  components: {
    logistics,
    navbarTools,
  },
  setup(props) {
    const { toClipboard } = useClipboard();
    const $route = useRoute();
    const state = reactive({
      OSS_URL: process.env.VUE_APP_OSS_URL,
      order_id: "", //订单id
      info: null, //物流数据
      expressName: $route.query.expressName,
      expressSn: $route.query.expressSn,
    });
    const copyFn = (msg) => {
      try {
        toClipboard(msg);
        Toast.success("复制成功");
      } catch (e) {
        Toast.fail(e);
      }
    };
    const getJdDeliverInfoFn = async () => {
      console.log($route.query.orderSn);
      let rs = await getJdDeliverInfo({ orderSn: $route.query.orderSn });
      if (rs.code === 0) {
        state.info = rs.data;
      }
    };
    getJdDeliverInfoFn();
    return {
      copyFn,
      ...toRefs(state),
    };
  },
});
// export default {
//   components: { logistics, navbarTools },
//   data() {
//     return {
//       order_id: "", //订单id
//       version: 2,
//       info: {}, //物流数据
//     };
//   },
//   created() {
//     this.order_id = this.$route.query.order_id;
//     this.getDeliveryDetail();
//   },
//   methods: {
//     //获取物流信息
//     getDeliveryDetail() {
//       // deliveryDetail({ order_id: this.order_id }).then((res) => {
//       //   if (res.code == 0) {
//       //     this.info = res.data;
//       //   }
//       // });
//     },
//     handleCopyLogistics() {
//       // 复制物流单号
//       this.$toast("复制成功");
//     },
//     handleCopyLogisticsErr() {
//       // 复制失败
//       this.$toast("复制失败");
//     },
//   },
// };
</script>
<style lang="scss" scoped>
.orderTracking {
  ::v-deep(.navbar) {
    width: 375px;
    .van-nav-bar {
      .van-nav-bar__left .van-icon {
        font-size: 24px;
        color: #000;
      }
      .van-nav-bar__title {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .jd-order-tracking-wrap {
    min-height: 100vh;
    padding-left: 0;
    padding-right: 0;
    .logistics-wrap {
      min-height: 440px;
    }
  }
}
</style>
